package com.islamabdalla.site.trial.medico

import com.islamabdalla.bos.common.medico.MedicoBalsamOnePageRef
import com.islamabdalla.site.trial.common.headerShadowOnScroll
import com.narbase.kunafa.core.components.View
import kotlinx.browser.window
import org.w3c.dom.asList

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/01/24.
 */
object MedicoLandingPageHydrate {
    fun hydrate(ref: MedicoBalsamOnePageRef) {

        ref.medicoTabsRefView?.mount(
            TabsSection(
                "/public/img/balsam-medico.png",
                listOf(
                    emrTabContent,
                    schedulingTabContent,
                    reportsTabContent,
                    financeTabContent
                ),
                "linear-gradient(90deg, #E6F6F6a7 0%, #BDEEEEa7 50%, #E2F4F4a7 100%)"
            )
        )

        headerShadowOnScroll(ref.headerView)
        hydrateMigrationAnimation(ref.curvesRoot)

    }

    private fun hydrateMigrationAnimation(curvesRoot: View?) {
        curvesRoot ?: return
//        console.log("curvesRoot.element.getElementsByTagName(svg)")
//        console.log(curvesRoot.element.getElementsByTagName("svg"))
        val list = curvesRoot.element.getElementsByClassName("migration-curve").asList()

        val migrationItems = curvesRoot.element.getElementsByClassName("migration-item").asList()
        console.log(migrationItems)

        list.forEachIndexed { curveIndex, element ->

            (1..4).forEach { migrationIconIndex ->
                val speedFactor = 3.5
                val numberOfIcons = 4
                window.setTimeout({
                    val original = migrationItems[migrationIconIndex]
                    val item = original.cloneNode(deep = true)
                    original.parentElement?.appendChild(item)
                    item.asDynamic().style.opacity = "1"
                    MigrationAnimation(numberOfIcons * 1000 * speedFactor, 0, element, item).start()
                }, ((curveIndex + migrationIconIndex) * 1000 * speedFactor).toInt())
            }

        }
    }

//    private fun View.getMigrationIcon(index: Int): View {
//        val imageWidth = 36
//        element.getElementsByClassName("migration-item")
////        return imageView {
////            style {
////                width = imageWidth.px
////                position = "absolute"
////                left = (-imageWidth / 2).px
////                top = (-imageWidth / 2).px
////            }
////            element.src = "/public/img/medico/migratable_$index.png"
////        }
//
//    }


    private val emrTabContent = FeatureTabContentDs(
        "Electronic Medical Record",
        "Powerful and flexible\n Electronic Medical Record",
        listOf("Autocomplete for input fields", "Customizable patient history and visit notes forms", "Voice notes"),
        "/public/img/emr.png"
    )
    private val schedulingTabContent = FeatureTabContentDs(
        "Scheduling",
        "Scheduling,\nDone right!", listOf(
            "Appointments reminders to minimize patients no-show.",
            "Access your schedule anytime, anywhere.",
            "SMS to patients when appointment is booked",
            "Allow your patients to book appointmetns online.",
        ), "/public/img/scheduling.png"
    )
    private val reportsTabContent = FeatureTabContentDs(
        "Dashboard & Reports",
        "Detailed reports,\nClearer image", listOf(
            "Intuitive dashboards, to get insights at a glance.",
            "Dive deeper with detailed finance and performance reports",
            "Review your staff performance with performance reports."
        ), "/public/img/dashboard.png"
    )
    private val financeTabContent = FeatureTabContentDs(
        "Finance",
        "Better finance tracking\nThe life line of your practice", listOf(
            "Simplify your accounting, track everything.",
            "Generate insurance reports",
            "Manage expenses, staff payments, revenue share, and more.",
            "Print bills with your clinic logo."
        ), "/public/img/finance.png"
    )
}