package com.islamabdalla.bos.common.data

import com.narbase.kunafa.core.drawable.Color

object AppColors {
    val warning = Color("EC9730")
    val main = Color(26, 114, 157)
    val text = Color("5e5e5e")
    val textLightGrey = Color("666")
    val textInputBorderColor = Color("cad1d1")
    val textDarkGrey = Color("9B9B9B")
    val textDarkerGrey = Color("4A4A4A")
    val textDarkestGrey = Color("222222")
    val textOffWhite = Color("DCE1E1")
    val lightGreyBackground = Color("4A4A4A")
    val lightBackground = Color("f1f2f2")
    val extraLightBackground = Color("#F8F8F8")
    val blueishGrey = Color("394a60")
    val mainDark = Color("1e252f")
    val hoverBackground = Color("F7F7F7")
    val separatorLight = Color("e4e4e4")
    val separatorDark = Color("303d4f")
    val separatorNormal = Color("BCBEC0")
    val greenLight = Color(86, 176, 90)
    val greenDark = Color(70, 160, 80)
    val redLight = Color(231, 82, 82)
    val redDark = Color("B03635")
    val balsamColor = Color("31c1c3")
    val lightBalsamColor = Color("ECF9F9")
    val borderColorHex = "#E6E7E8"
    val borderColor = Color("D2D8D8")
    val narnicDarkColor = Color("0098AC")
    val narnicVeryDarkColor = Color("495F7C")
    //Footer

    val black = Color("#000000")
    val white = Color("#ffffff")

    val existingColor = textDarkGrey

}
