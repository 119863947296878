package com.islamabdalla.site.trial.one

import com.islamabdalla.bos.common.one.ThankYouPageRef
import kotlinx.browser.window

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/01/24.
 */
object ThankYouPageHydrate {
    fun hydrate(ref: ThankYouPageRef) {
        ref.backToHome?.onClick = {
            window.top.location.href = "http://balsam.narbase.com/"
            asDynamic()
        }
    }


}